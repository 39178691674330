import {ImageService} from '@wix/wix-events-commons-statics/dist/image'
import * as React from 'react'
import {getImageStyle} from '../../../utils/image'
import * as s from './image.scss'
import {ImageProps, ImageState} from './interfaces'

export class Image extends React.Component<ImageProps, ImageState> {
  state: ImageState = {element: null}

  setRef = (element: HTMLElement) => this.setState({element})

  getStyle = () => {
    const {image, opacity, gridPosition, fitImage, square} = this.props
    const {element} = this.state

    if (!element) {
      return {}
    }

    const {width: containerWidth, height: containerHeight} = element.getBoundingClientRect()
    const size = ImageService.getResizedImageSize(image.width, image.height, containerWidth, containerHeight)

    return {
      ...getImageStyle(image, size, opacity, gridPosition, fitImage ? 'contain' : 'cover'),
      ...(square ? {height: 'auto', paddingTop: '100%'} : {})
    }
  }

  render() {
    const {image} = this.props

    return image && image.id ? <div className={s.container} style={this.getStyle()} ref={this.setRef} /> : null
  }
}
