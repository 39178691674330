import {ImageService} from '@wix/wix-events-commons-statics'
import * as React from 'react'
import {EventImageProps} from '.'
import {getImageStyle} from '../../utils/image'
import * as s from './event-image.scss'

export class EventImage extends React.PureComponent<EventImageProps> {
  fastCachedImageStyle: React.CSSProperties
  fastCachedImageId: string
  cachedImageStyle: React.CSSProperties
  cachedImageId: string

  imageAvailable = () => Boolean(this.props.event.mainImage && this.props.event.mainImage.id)

  getImageStyle = (fastLoad = false) => {
    let {event, imagePosition, imageOpacity, width, height} = this.props

    if (fastLoad) {
      width = Math.ceil(width / 50)
      height = Math.ceil(height / 50)
    }

    if (fastLoad) {
      if (event.mainImage.id === this.fastCachedImageId) {
        return this.cachedImageStyle
      }
    } else {
      if (event.mainImage.id === this.fastCachedImageId) {
        return this.fastCachedImageStyle
      }
    }

    const container = ImageService.getResizedImageSize(event.mainImage.width, event.mainImage.height, width, height)
    const imageStyle = getImageStyle(event.mainImage, container, imageOpacity, imagePosition)

    this.cachedImageStyle = imageStyle
    this.cachedImageId = event.mainImage.id

    return imageStyle
  }

  render() {
    return this.imageAvailable() ? (
      <div className={s.imageContainer}>
        <div className={s.fastLoadImage} style={this.getImageStyle(true)} data-hook="fastLoadImage" />
        <div className={s.image} style={this.getImageStyle()} data-hook="image" />
      </div>
    ) : null
  }
}
