import {withNamespaces, WithNamespaces} from 'react-i18next'
import {getFullLocale, isEditor, isMobile} from '../../../commons/selectors/environment'
import {getFirstEvent} from '../../selectors/events'
import {
  isAllComponentsHidden,
  isButtonLayout,
  isCompactDividerVisible,
  isHorizontalLayout,
  isImageVisible,
  isOnImageLayout,
  isVerticalLayout
} from '../../selectors/settings'
import {resizeToDefault} from '../../services/resize'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {SingleEvent as SingleEventPresentation} from './single-event'

interface SingleEventOwnProps {}

interface SingleEventStateProps {
  event: wix.events.Event
  layout: number
  alignment: number
  verticalAlignment: number
  showDate: boolean
  showLocation: boolean
  compactDividerVisible: boolean
  compactDividerWidth: number
  compactDividerHeight: number
  buttonLayout: boolean
  onImageLayout: boolean
  horizontalLayout: boolean
  verticalLayout: boolean
  imageVisible: boolean
  imageWidth: number
  imagePosition: number
  verticalImagePosition: number
  mobile: boolean
  editor: boolean
  fullLocale: string
  height: number
  width: number
  rtl: boolean
  resizeToDefault: (layout?: string | number) => void
}

export interface SingleEventProps extends SingleEventOwnProps, SingleEventStateProps, WithNamespaces {}

const mapState = ({state, dimensions, isRTL}: AppProps): SingleEventStateProps => ({
  event: getFirstEvent(state),
  layout: state.component.settings.widgetLayout,
  alignment: state.component.settings.alignment,
  verticalAlignment: state.component.settings.contentAlignment,
  showDate: state.component.settings.showDate,
  showLocation: state.component.settings.showLocation,
  compactDividerVisible: isCompactDividerVisible(state),
  compactDividerWidth: state.component.settings.compactHorizontalDividerWidth,
  compactDividerHeight: state.component.settings.compactHorizontalDividerHeight,
  buttonLayout: isButtonLayout(state) || isAllComponentsHidden(state),
  onImageLayout: isOnImageLayout(state),
  horizontalLayout: isHorizontalLayout(state),
  verticalLayout: isVerticalLayout(state),
  imageVisible: isImageVisible(state),
  imageWidth: state.component.settings.imageWidth,
  imagePosition: state.component.settings.imagePosition,
  verticalImagePosition: state.component.settings.verticalImagePosition,
  mobile: isMobile(state),
  editor: isEditor(state),
  fullLocale: getFullLocale(state),
  height: dimensions.height,
  width: dimensions.width,
  rtl: isRTL,
  resizeToDefault: layout => resizeToDefault(state, layout)
})

export const SingleEvent = connect<SingleEventOwnProps, SingleEventStateProps>(mapState)(
  withNamespaces()(SingleEventPresentation)
)
