import {isMobile} from '../../../../commons/selectors/environment'
import {getAlignmentClassName} from '../../../selectors/classes'
import {
  getDateAndLocationMargin,
  isDateAndLocationVisible,
  isDateVisible,
  isHorizontalDateAndLocation,
  isLocationVisible,
  isVerticalDividersVisible
} from '../../../selectors/settings'
import {AppProps} from '../../app/interfaces'
import {renderIfVisible} from '../../hoc/render-if-visible'
import {withMargin} from '../../hoc/with-margin'
import {connect} from '../../runtime-context/connect'
import {DateLocation as DateLocationPresentation} from './date-location'

interface DateLocationOwnProps {
  event: wix.events.Event
}

interface DateLocationStateProps {
  dateVisible: boolean
  locationVisible: boolean
  dividerVisible: boolean
  verticalDividerWidth: number
  verticalDividerHeight: number
  horizontalDividerWidth: number
  horizontalDividerHeight: number
  horizontal: boolean
  alignmentClassName: string
  isMobile: boolean
}

export interface DateLocationProps extends DateLocationOwnProps, DateLocationStateProps {}

const mapState = ({state}: AppProps): DateLocationStateProps => ({
  dateVisible: isDateVisible(state),
  locationVisible: isLocationVisible(state),
  dividerVisible: isVerticalDividersVisible(state),
  verticalDividerWidth: state.component.settings.verticalDividerWidth,
  verticalDividerHeight: state.component.settings.verticalDividerHeight,
  horizontalDividerWidth: state.component.settings.horizontalDividerWidth,
  horizontalDividerHeight: state.component.settings.horizontalDividerHeight,
  horizontal: isHorizontalDateAndLocation(state),
  alignmentClassName: getAlignmentClassName(state.component.settings.alignment),
  isMobile: isMobile(state)
})

const getMargin = ({state}: AppProps) => getDateAndLocationMargin(state)

export const DateLocation = renderIfVisible(
  withMargin(connect<DateLocationOwnProps, DateLocationStateProps>(mapState)(DateLocationPresentation), getMargin),
  isDateAndLocationVisible
)
