import {getDayOfMonth} from '@wix/wix-events-commons-statics/dist/date/date'
import * as React from 'react'
import {EventInfoProps} from '.'
import * as s from './day-events.scss'

export class DayEvents extends React.PureComponent<EventInfoProps> {
  render() {
    const {events, firstEventTime, t} = this.props

    if (!events.length) {
      return null
    }

    const day = getDayOfMonth(events[0].scheduling.config.startDate)

    return (
      <div className={s.info}>
        <div className={s.time}>{firstEventTime}</div>
        <div className={s.title}>
          <div className={s.ellipsis} data-hook={`cell-event-title-${day}`}>
            {events[0].title}
          </div>
        </div>
        {events.length > 1 ? (
          <div className={s.more} data-hook={`more-link-${day}`}>
            {t('layout.calendar.monthly.events.moreLink', {amount: events.length - 1})}
          </div>
        ) : null}
      </div>
    )
  }
}
