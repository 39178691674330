import {isEditor, isMobile} from '../../../commons/selectors/environment'
import {closeMonthlyCalendarPopup} from '../../actions/calendar-layout'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {Calendar as CalendarPresentation} from './calendar'

export interface CalendarOwnProps {}

export interface CalendarRuntimeProps {
  isEditor: boolean
  isMobile: boolean
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
}

export interface CalendarProps extends CalendarRuntimeProps, CalendarOwnProps {}

export interface CalendarState {
  forceMobileLayout: boolean
}

const mapRuntime = ({state, actions}: AppProps): CalendarRuntimeProps => ({
  isEditor: isEditor(state),
  isMobile: isMobile(state),
  closeMonthlyCalendarPopup: actions.closeMonthlyCalendarPopup
})

export const Calendar = connect<CalendarOwnProps, CalendarRuntimeProps>(mapRuntime)(CalendarPresentation)
