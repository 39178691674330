import {
  SocialBar as SocialBarCommons,
  SocialBarProps as SocialBarCommonsProps
} from '../../../commons/components/social-bar'
import {shareEvent} from '../../../commons/services/share'
import {isShareOptionsVisible} from '../../selectors/settings'
import {AppProps} from '../app/interfaces'
import {renderIfVisible} from '../hoc/render-if-visible'
import {connect} from '../runtime-context/connect'

export interface SocialBarConnectProps {
  event: wix.events.Event
}

export interface SocialBarStateProps {
  share: Function
  eventUrl: string
}

export interface SocialBarProps extends Partial<SocialBarCommonsProps>, SocialBarConnectProps {}

const mapRuntime = ({pageUrl}: AppProps, {event}: SocialBarConnectProps) => {
  const eventUrl = `${pageUrl ? pageUrl.url : ''}/${event.slug}`

  return {
    share: shareEvent,
    eventUrl
  }
}

export const SocialBar = connect<SocialBarProps, SocialBarStateProps>(mapRuntime)(SocialBarCommons)

export const SingleEventSocialBar = renderIfVisible<SocialBarProps>(SocialBar, isShareOptionsVisible)
