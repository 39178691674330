import {createContext} from 'react'

export const RuntimeContext = createContext({
  state: undefined,
  actions: undefined,
  dimensions: {
    height: undefined,
    width: undefined,
    left: undefined,
    top: undefined
  }
})
