import {ISantaProps} from 'native-components-infra/dist/src/types/types'
import * as React from 'react'
import {RuntimeContext} from './runtime-context'

interface AppPropsBase {
  state: any
  actions: any
  dimesions: {
    height: any
    width: any
    left: any
    top: any
  }
}

export function typedConnect<AppProps>() {
  return function<OwnProps = {}, StateProps = {}>(
    mapRuntimeToProps: (context: Partial<AppPropsBase & AppProps>, props?: OwnProps) => StateProps
  ) {
    return function(
      WrappedComponent: React.ComponentClass | React.FunctionComponent<OwnProps & StateProps>
    ): React.ComponentClass<OwnProps> {
      class ConnectedComponent extends React.Component<OwnProps> {
        context: React.ContextType<typeof RuntimeContext>
        static contextType = RuntimeContext

        render() {
          const props = mapRuntimeToProps(this.context, this.props)
          return <WrappedComponent {...this.props} {...props} />
        }
      }

      return ConnectedComponent
    }
  }
}

export const connect = typedConnect<ISantaProps>()
