import * as React from 'react'
import {ImageProps} from '.'
import {DH} from '../../../../utils/data-hooks'
import {Image as CommonImage} from '../../../commons/image'
import * as s from './image.scss'

export const Image = ({event, containerWidth = '100%', containerHeight = '100%', squareImage = false}: ImageProps) => {
  const style = {
    width: containerWidth,
    height: squareImage ? '100%' : containerHeight
  }

  if (event.mainImage && event.mainImage.id) {
    return (
      <div data-hook={DH.listImage} className={s.listImageBackgroundColor} style={style}>
        <CommonImage image={event.mainImage} square={squareImage} />
      </div>
    )
  }

  const placeholderStyle = {...style, paddingTop: squareImage ? '100%' : 'initial'}

  return <div data-hook={DH.listImagePlaceholder} className={s.listImageBackgroundColor} style={placeholderStyle} />
}
