import * as classNames from 'classnames'
import * as React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import ChevronRight from 'wix-ui-icons-common/ChevronRight'
import {MobileMonthlyCalendarProps, MobileMonthlyCalendarState} from '.'
import {Title} from '../../../list-title'
import {TodayButton} from '../../today-button'
import {MobileMonthlyDays} from './days'
import * as s from './mobile-monthly-calendar.scss'

export class MobileMonthlyCalendar extends React.PureComponent<MobileMonthlyCalendarProps, MobileMonthlyCalendarState> {
  state: MobileMonthlyCalendarState = {
    datesLoading: true
  }

  componentDidMount() {
    this.setState({datesLoading: false})
  }

  render() {
    const {subCalendarMonth, addCalendarMonth, resetCalendar, fullMonthText, referenceDate} = this.props

    return referenceDate ? (
      <div data-hook={'monthly-mobile-calendar'}>
        <Title />
        <div className={s.controls}>
          <div className={s.monthControls}>
            <button
              onClick={() => subCalendarMonth()}
              type="button"
              className={classNames(s.back, s.monthButton)}
              data-hook={'calendar-previous-month-button'}
            >
              <ChevronLeft size="1.5em" />
            </button>
            <div className={classNames(s.month, {[s.loading]: this.state.datesLoading})} data-hook={'calendar-month'}>
              {fullMonthText}
            </div>
            <button
              onClick={() => addCalendarMonth()}
              type="button"
              className={classNames(s.next, s.monthButton)}
              data-hook={'calendar-next-month-button'}
            >
              <ChevronRight size="1.5em" />
            </button>
          </div>
          <TodayButton onClick={resetCalendar} dataHook={'calendar-today-button'} />
        </div>
        <div className={s.days}>
          <MobileMonthlyDays />
        </div>
      </div>
    ) : null
  }
}
