import * as classNames from 'classnames'
import * as React from 'react'
import {TodayButtonProps} from '.'
import {ButtonStyle} from '../../../types/state'
import * as sc from '../../classes.scss'
import * as s from './today-button.scss'

export class TodayButton extends React.PureComponent<TodayButtonProps> {
  getStyleClassNames = () => {
    const {todayButtonStyle} = this.props

    return {
      [s.full]: todayButtonStyle === ButtonStyle.FULL || todayButtonStyle === ButtonStyle.FULL_ROUNDED,
      [s.rounded]: todayButtonStyle === ButtonStyle.FULL_ROUNDED || todayButtonStyle === ButtonStyle.HOLLOW_ROUNDED
    }
  }

  render() {
    const {onClick, dataHook, isMobile, t} = this.props

    return (
      <button
        data-hook={dataHook}
        onClick={() => onClick()}
        type="button"
        className={classNames(s.container, sc.ellipsis, this.getStyleClassNames(), {[s.mobile]: isMobile})}
      >
        {this.props.todayButtonText || t('layout.calendar.monthly.controls.today')}
      </button>
    )
  }
}
