import imageSDK from 'image-client-api/dist/imageClientSDK'

export const getImageStyle = (
  image: wix.events.Image,
  {width, height}: Container,
  opacity = 100,
  position = IMAGE_POSITION.CENTER_CENTER,
  backgroundSize: 'cover' | 'contain' = 'cover'
) => {
  return {
    backgroundImage: `url(${imageSDK.getScaleToFillImageURL(image.id, image.width, image.height, width, height, {
      quality: 90
    })})`,
    backgroundPosition: resolveImagePosition(position),
    backgroundRepeat: 'no-repeat',
    backgroundSize,
    opacity: opacity / 100
  }
}

enum IMAGE_POSITION {
  CENTER_CENTER = 5
}

const resolveImagePosition = (position: IMAGE_POSITION) => {
  let horizontal = 'center',
    vertical = ''

  if (position % 3 === 0) {
    horizontal = 'right'
  } else if ((position - 1) % 3 === 0) {
    horizontal = 'left'
  }

  if (position < 4) {
    vertical = 'top'
  } else if (position < 7) {
    vertical = 'center'
  } else {
    vertical = 'bottom'
  }

  return `${horizontal} ${vertical}`
}

interface Container {
  width: number
  height: number
}
