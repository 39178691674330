import {LocalStorage} from '@wix/wix-events-commons-statics'
import * as classNames from 'classnames'
import * as React from 'react'
import {SingleEventProps} from '.'
import {HORIZONTAL_IMAGE_POSITION, VERTICAL_IMAGE_POSITION} from '../../constants/settings'
import {getAlignmentClassName, getVerticalAlignmentClassName} from '../../selectors/classes'
import {SingleEventShortDateLocation as ShortDateLocation} from '../short-date-location'
import {SingleEventSocialBar as SocialBar} from '../social-bar'
import {Countdown} from './countdown'
import {DateLocation} from './date-location'
import {Description} from './description'
import {Divider} from './divider'
import {Image} from './image'
import {RsvpButton} from './rsvp-button'
import * as s from './single-event.scss'
import {Title} from './title'

export class SingleEvent extends React.Component<SingleEventProps> {
  componentDidMount() {
    if (this.props.editor && LocalStorage.getItem('evResize')) {
      this.props.resizeToDefault(this.props.layout)
      LocalStorage.removeItem('evResize')
    }

    if (this.props.mobile) {
      this.props.resizeToDefault()
    }
  }

  async componentDidUpdate() {
    if (this.props.mobile) {
      this.props.resizeToDefault()
    }
  }

  getContainerStyle = () => {
    const {horizontalLayout, verticalLayout, imagePosition, verticalImagePosition, mobile, height, rtl} = this.props

    const style: React.CSSProperties = {
      height
    }

    if (mobile) {
      style.flexDirection =
        verticalLayout && verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM ? 'column-reverse' : 'column'
    } else if (verticalLayout) {
      style.flexDirection = verticalImagePosition === VERTICAL_IMAGE_POSITION.BOTTOM ? 'column-reverse' : 'column'
    } else if (horizontalLayout) {
      if (imagePosition === HORIZONTAL_IMAGE_POSITION.LEFT) {
        style.flexDirection = rtl ? 'row-reverse' : 'row'
      } else {
        style.flexDirection = rtl ? 'row' : 'row-reverse'
      }
    }

    return style
  }

  getContentContainerStyle = () => {
    const {horizontalLayout, verticalLayout, onImageLayout, imageVisible, imageWidth, mobile} = this.props

    const style: any = {}

    if (mobile) {
      style.width = '100%'
      style.height = imageVisible && !onImageLayout ? `${100 - imageWidth}%` : '100%'
    } else {
      style.width = imageVisible && horizontalLayout ? `${100 - imageWidth}%` : '100%'
      style.height = imageVisible && verticalLayout ? `${100 - imageWidth}%` : '100%'
    }

    return style
  }

  render() {
    const {
      event,
      alignment,
      verticalAlignment,
      showDate,
      showLocation,
      compactDividerVisible,
      compactDividerWidth,
      compactDividerHeight,
      buttonLayout,
      onImageLayout,
      mobile,
      fullLocale,
      t
    } = this.props

    const socialBarClassName = onImageLayout ? s.onImageSocial : s.social

    return (
      <div
        className={classNames(s.container, {
          [s.fullLayout]: !buttonLayout,
          [s.buttonLayout]: buttonLayout,
          [s.onImageLayout]: onImageLayout,
          [s.mobile]: mobile
        })}
        style={this.getContainerStyle()}
        id={'ev-frame'}
      >
        <Image event={event} />
        <div
          style={this.getContentContainerStyle()}
          id={'ev-content-container'}
          className={classNames(
            s.contentContainer,
            s[getAlignmentClassName(alignment)],
            s[getVerticalAlignmentClassName(verticalAlignment)]
          )}
        >
          <div id={'ev-content'} className={s.content}>
            <Countdown event={event} t={t} onImage={onImageLayout} isMobile={mobile} />
            <Title event={event} onImage={onImageLayout} isMobile={mobile} />
            <ShortDateLocation
              event={event}
              alignment={alignment}
              showDate={showDate}
              showLocation={showLocation}
              fullLocale={fullLocale}
              onImage={onImageLayout}
              mobile={mobile}
            />
            {compactDividerVisible && (
              <div className={s.compactDivider}>
                <Divider width={compactDividerWidth} height={compactDividerHeight} />
              </div>
            )}
            <Description event={event} onImage={onImageLayout} isMobile={mobile} />
            <div>
              <DateLocation event={event} />
            </div>
            <RsvpButton event={event} />
            <SocialBar hideTitle spacing={36} t={t} className={socialBarClassName} event={event} />
          </div>
        </div>
      </div>
    )
  }
}
