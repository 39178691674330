import {openMonthlyCalendarEvent} from '../../../../../../../actions/calendar-layout'
import {getFormattedStartTime} from '../../../../../../../selectors/events'
import {AppProps} from '../../../../../../app/interfaces'
import {connect} from '../../../../../../runtime-context/connect'
import {MobileMonthlyEvent as MobileMonthlyEventPresentation} from './mobile-monthly-event'

export interface MobileMonthlyEventOwnProps {
  event: wix.events.Event
  date: Date
}

export interface MobileMonthlyEventRuntimeProps {
  selectedEventId: string
  eventTime: string
  openMonthlyCalendarEvent: typeof openMonthlyCalendarEvent
}

export interface MobileMonthlyEventProps extends MobileMonthlyEventRuntimeProps, MobileMonthlyEventOwnProps {}

const mapRuntime = (
  {state, actions}: AppProps,
  ownProps: MobileMonthlyEventOwnProps
): MobileMonthlyEventRuntimeProps => ({
  selectedEventId: state.calendarLayout.monthly.selectedEventId,
  eventTime: getFormattedStartTime(state, ownProps.event.scheduling.config),
  openMonthlyCalendarEvent: actions.openMonthlyCalendarEvent
})

export const MobileMonthlyEvent = connect<MobileMonthlyEventOwnProps, MobileMonthlyEventRuntimeProps>(mapRuntime)(
  MobileMonthlyEventPresentation
)
