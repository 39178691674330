import * as classNames from 'classnames'
import * as React from 'react'
import {Animator} from 'wix-animations'
import {ItemProps} from '.'
import * as sc from '../../classes.scss'
import {FullDateLocation} from '../../full-date-location'
import {RsvpButton} from '../../rsvp-button'
import * as s from '../item-container/item.scss'
import {dateWidth, Date} from './date'
import {Description} from './description'
import {Image} from './image'
import {Share} from './share'
import {Title} from './title'
import {TitleLocation} from './title-location'

export const DesktopItem = ({
  t,
  event,
  opened,
  hovered,
  showDate,
  showImage,
  showVenue,
  listLayout,
  fullLocale,
  listShowFullDate,
  listShowLocation,
  listShowDescription,
  additionalComponentsHidden,
  rtl
}: ItemProps) => {
  const active = opened || hovered
  const animatedPartWidth = getAnimatedPartWidth({showImage, showDate})
  const animatedPartStyle = active ? {[rtl ? 'right' : 'left']: -animatedPartWidth} : null

  return (
    <>
      <div className={classNames(s.collapsedContainer)}>
        <div className={classNames(s.left, {[s.leftOpened]: opened})} style={animatedPartStyle} aria-hidden={opened}>
          <div className={sc.row} style={{width: animatedPartWidth}}>
            <Image event={event} containerWidth={80} containerHeight={80} />
            <Date event={event} t={t} fullLocale={fullLocale} />
          </div>
          <div className={s.titleContainer} style={{width: `calc(100% - ${active ? 0 : animatedPartWidth}px)`}}>
            <TitleComponent
              event={event}
              hovered={hovered}
              opened={opened}
              listLayout={listLayout}
              showVenue={showVenue}
              additionalComponentsHidden={additionalComponentsHidden}
            />
          </div>
        </div>
        <div className={s.button}>
          <RsvpButton event={event} minWidth={100} maxWidth={160} />
        </div>
      </div>
      <Animator height={calculateContentHeight} show={opened && !additionalComponentsHidden}>
        <div className={classNames(s.textContent, s.listContentText)}>
          <FullDateLocation
            event={event}
            showDate={listShowFullDate}
            showLocation={listShowLocation}
            fullLocale={fullLocale}
          />
          <Description event={event} visible={listShowDescription} />
          <Share t={t} event={event} />
        </div>
      </Animator>
    </>
  )
}

interface TitleComponentProps {
  event: wix.events.Event
  hovered: boolean
  opened: boolean
  listLayout: boolean
  showVenue: boolean
  additionalComponentsHidden: boolean
}

const TitleComponent = ({
  event,
  hovered,
  opened,
  listLayout,
  showVenue,
  additionalComponentsHidden
}: TitleComponentProps) => {
  if (hovered || opened) {
    return (
      <Title
        active
        event={event}
        opened={opened}
        listLayout={listLayout}
        showArrow={!additionalComponentsHidden}
        withOverflow={!opened}
      />
    )
  }

  return <TitleLocation event={event} showVenue={showVenue} />
}

const calculateContentHeight = (element: HTMLElement) => element.offsetHeight + 60

export const getAnimatedPartWidth = ({showImage, showDate}: {showImage: boolean; showDate: boolean}) => {
  let width = 210

  if (!showImage) {
    width = width - 102
  }

  if (!showDate) {
    width = width - dateWidth
  }

  return width
}
