import {getImageMargins, isImageVisible} from '../../../../selectors/list-settings'
import {AppProps} from '../../../app/interfaces'
import {renderIfVisible} from '../../../hoc/render-if-visible'
import {withMargin} from '../../../hoc/with-margin'
import {Image as ImagePresentation} from './image'

interface ImageOwnProps {
  event: wix.events.Event
  containerWidth?: number | string
  containerHeight?: number | string
  squareImage?: boolean
}

export interface ImageProps extends ImageOwnProps {}

const getMargins = ({state, dimensions, isRTL}: AppProps) => getImageMargins(state, dimensions, isRTL)

export const Image = renderIfVisible<ImageOwnProps>(withMargin(ImagePresentation, getMargins), isImageVisible)
