import {withNamespaces, WithNamespaces} from 'react-i18next'
import {isMobile} from '../../../commons/selectors/environment'
import {getEvents} from '../../selectors/events'
import {isListLayout, isNarrow} from '../../selectors/list-settings'
import {AppProps} from '../app/interfaces'
import {connect} from '../runtime-context/connect'
import {ListLayout as ListLayoutPresentation} from './list-layout'

interface ListLayoutStateProps {
  events: wix.events.Event[]
  narrow: boolean
  mobile: boolean
  listLayout: boolean
}

interface ListLayoutOwnProps {}

export interface ListLayoutProps extends ListLayoutStateProps, ListLayoutOwnProps, WithNamespaces {}

const mapRuntime = ({state, dimensions}: AppProps): ListLayoutStateProps => ({
  events: getEvents(state),
  narrow: isNarrow(state, dimensions),
  mobile: isMobile(state),
  listLayout: isListLayout(state)
})

export const ListLayout = connect<ListLayoutOwnProps, ListLayoutStateProps>(mapRuntime)(
  withNamespaces()(ListLayoutPresentation)
)
