import * as classNames from 'classnames'
import * as React from 'react'
import {TitleProps} from '.'
import {DH} from '../../utils/data-hooks'
import * as sc from '../classes.scss'
import * as s from './title.scss'

export const Title = ({title, listLayout, listStripWidth, mobile, visible}: TitleProps) => {
  if (!visible) {
    return null
  }

  return (
    <div
      className={classNames(s.container, sc.textLineHeight, {[s.mobile]: mobile})}
      style={{
        padding: listLayout
          ? `${mobile ? '20px' : '55px'} 30px ${mobile ? 20 : 50 + listStripWidth}px`
          : `${mobile ? '20px' : 'calc(72px - .3em)'} 30px ${mobile ? '20px' : 'calc(52px - .3em)'}`
      }}
      data-hook={DH.listTitle}
    >
      {title}
    </div>
  )
}
