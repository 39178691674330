import * as classNames from 'classnames'
import * as React from 'react'
import ChevronLeft from 'wix-ui-icons-common/ChevronLeft'
import X from 'wix-ui-icons-common/X'
import {PopupHeaderProps} from '.'
import * as s from './popup-header.scss'

export class PopupHeader extends React.PureComponent<PopupHeaderProps> {
  handleBackKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.onBack && this.props.onBack()
    }
  }

  handleCloseKeyPress = event => {
    if (event.key === 'Enter') {
      this.props.onClose()
    }
  }

  render() {
    const {onBack, text, onClose, eventList, eventDetails, t} = this.props
    const backClickHandler = onBack || (() => {})

    return (
      <div>
        <div
          className={classNames(s.date, {
            [s.dateWithChevron]: onBack,
            [s.eventList]: eventList,
            [s.eventDetails]: eventDetails
          })}
        >
          {onBack ? (
            <div
              className={s.dateChevron}
              onClick={backClickHandler}
              onKeyPress={this.handleBackKeyPress}
              tabIndex={0}
              data-hook={`calendar-popup-back-button`}
            >
              <ChevronLeft size="24px" />
            </div>
          ) : null}
          <div
            className={s.dateText}
            onClick={backClickHandler}
            onKeyPress={this.handleBackKeyPress}
            data-hook={`calendar-popup-title`}
          >
            {text}
          </div>
        </div>
        <div
          className={s.close}
          onClick={onClose}
          onKeyPress={this.handleCloseKeyPress}
          tabIndex={0}
          role="button"
          aria-label={t('a11y.close')}
          data-hook={`calendar-popup-close-button`}
        >
          <X size="16px" />
        </div>
      </div>
    )
  }
}
