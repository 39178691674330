import {RegistrationStatus} from '@wix/wix-events-commons-statics/dist/types/enums/events'
import {RsvpStatusOptions} from '@wix/wix-events-commons-statics/dist/types/enums/RsvpCollectionConfig'

export const isEventExternalRegistration = (event: wix.events.Event) => {
  return event.registration.status === RegistrationStatus.OPEN_EXTERNAL
}

export const isNoResponseEnabled = (event: wix.events.Event) =>
  event.registration.rsvpCollection.config.rsvpStatusOptions === RsvpStatusOptions.YES_AND_NO

export const getTimeZoneId = (event: wix.events.Event) =>
  (event && !isDateTbd(event) && event.scheduling.config.timeZoneId) || 'UTC'

export const isDateTbd = (event: wix.events.Event) => event.scheduling.config.scheduleTbd
