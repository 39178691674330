import * as classNames from 'classnames'
import * as React from 'react'
import {MonthlyCalendarCellRefs, MonthlyCalendarCellSizes, MonthlyCalendarGridProps, MonthlyCalendarGridState} from '.'
import {CalendarCell} from './cell'
import * as s from './monthly-calendar-grid.scss'
import {MonthlyCalendarPopup} from './popup'

export class MonthlyCalendarGrid extends React.PureComponent<MonthlyCalendarGridProps, MonthlyCalendarGridState> {
  state: MonthlyCalendarGridState = {
    datesLoading: true
  }

  cellRefs: MonthlyCalendarCellRefs = {}

  componentDidMount() {
    this.setState({datesLoading: false})
  }

  getCellSizes = (): MonthlyCalendarCellSizes =>
    Object.entries(this.cellRefs).reduce((cellSizes, [week, weekRefs]) => {
      cellSizes[week] = Object.entries(weekRefs).reduce((weekSizes, [weekDay, ref]) => {
        const {clientWidth: width, clientHeight: height} = ref as HTMLElement
        weekSizes[weekDay] = {width, height}

        return weekSizes
      }, {})

      return cellSizes
    }, {})

  registerCellContainerRef = (week: number, weekDay: number, ref: HTMLElement) => {
    this.cellRefs[week] = this.cellRefs[week] || {}
    this.cellRefs[week][weekDay] = ref
  }

  render() {
    const {calendarWeeks, weekdayNames, borderStyle, t} = this.props

    return (
      <div className={s.grid}>
        <div className={s.weekDays}>
          {weekdayNames.map(name => (
            <div className={classNames(s.weekDay, {[s.loading]: this.state.datesLoading})}>{name}</div>
          ))}
        </div>
        <div className={s.days}>
          {calendarWeeks.map((weekDays, week) => (
            <div className={s.week} style={borderStyle}>
              {weekDays.map((dayInfo, weekDay) => (
                <CalendarCell
                  day={dayInfo}
                  week={week}
                  weekDay={weekDay}
                  registerContainerRef={this.registerCellContainerRef}
                />
              ))}
            </div>
          ))}
          <MonthlyCalendarPopup getCellSizes={this.getCellSizes} t={t} />
        </div>
      </div>
    )
  }
}
