import {isMobile, isSite} from '../../commons/selectors/environment'
import {getElementHeight, getElementStyle, getElementWidth, setElementStyle} from '../../commons/utils/dom'
import {resizeComponent, setHeight} from '../../commons/utils/wix-sdk'
import {OLD_WIDGET_LAYOUT, WIDGET_LAYOUT} from '../constants/settings'
import {
  includeImageToCalculations,
  isAllComponentsHidden,
  isButtonLayout,
  isImageVisible,
  isSingleEventWidget
} from '../selectors/settings'
import {State} from '../types/state'

const CONTENT_SELECTOR: string = '#ev-content'
const CONTENT_CONTAINER_SELECTOR: string = '#ev-content-container'
const FRAME_SELECTOR: string = '#ev-frame'
const BTN_SELECTOR: string = '#ev-rsvp-button'

export const resizeToDefault = (state: State, layout?: string | number) => {
  switch (layout) {
    case OLD_WIDGET_LAYOUT.FULL:
    case WIDGET_LAYOUT.FULL:
      resizeToFullLayout(state)
      break
    case OLD_WIDGET_LAYOUT.VERTICAL:
    case WIDGET_LAYOUT.VERTICAL:
      resizeToVerticalLayout(state)
      break
    case OLD_WIDGET_LAYOUT.BACKGROUND:
    case WIDGET_LAYOUT.BACKGROUND:
      resizeToBackgroundLayout()
      break
    case OLD_WIDGET_LAYOUT.BUTTON_ONLY:
    case WIDGET_LAYOUT.BUTTON_ONLY:
      resizeToButtonOnlyLayout(state)
      break
    default:
      resizeWidget(state)
      break
  }
}

const resizeToFullLayout = (state: State) => {
  resizeComponent({width: isImageVisible(state) ? 860 : 550})
    .catch(() => null)
    .then(() => {
      setHeight(getMinWidgetHeight(state))
    })
}

const resizeToVerticalLayout = (state: State) => {
  resizeComponent({width: 600})
    .catch(() => null)
    .then(() => {
      let height = getMinWidgetHeight(state)

      if (isImageVisible(state)) {
        height = (height * 100) / (100 - state.component.settings.imageWidth)
      }

      setHeight(height)
    })
}

const resizeToBackgroundLayout = () => {
  resizeComponent({width: 550})
    .catch(() => null)
    .then(() => setHeight(550))
}

const resizeToButtonOnlyLayout = (state: State) => {
  resizeComponent({width: getMinWidgetWidth(state) + 2}) // + 2 - space around for outline
    .catch(() => null)
    .then(() => setHeight(getMinWidgetHeight(state) + 2))
}

const resizeWidget = (state: State) => {
  const mobile = isMobile(state)
  const minHeight = getMinWidgetHeight(state)

  if (mobile || getCurrentWidgetHeight(state) < minHeight) {
    setHeight(minHeight)
  }

  if (mobile && isSite(state)) {
    setElementStyle(getElement(state, FRAME_SELECTOR), 'height', `${minHeight}px`)
  }
}

const getMinWidgetWidth = (state: State) =>
  getWidgetButtonsWidth(state) + getWidgetBorderWidth(state) + getWidgetHorizontalPadding(state)

const getMinWidgetHeight = (state: State) => {
  const contentHeight = getContentHeight(state)
  const imageHeight = includeImageToCalculations(state) ? getImageHeight(state) : 0

  return contentHeight + imageHeight
}

const getCurrentWidgetHeight = (state: State) => getElementHeight(FRAME_SELECTOR, getContainer(state))

const getCurrentContentHeight = (state: State) => getElementHeight(CONTENT_SELECTOR, getContainer(state))

const getContentHeight = (state: State) =>
  getWidgetBorderWidth(state) + getWidgetVerticalPadding(state) + getCurrentContentHeight(state)

const getImageHeight = (state: State) =>
  (getContentHeight(state) / (100 - state.component.settings.imageWidth)) * state.component.settings.imageWidth

const getWidgetButtonsWidth = (state: State) => getElementWidth(BTN_SELECTOR, getContainer(state))

const getWidgetBorderWidth = (state: State) =>
  (parseInt(getElementStyle(getElement(state, FRAME_SELECTOR), 'borderWidth'), 10) || 0) * 2

const getWidgetHorizontalPadding = (state: State) =>
  (parseInt(getElementStyle(getElement(state, CONTENT_CONTAINER_SELECTOR), 'paddingRight'), 10) || 0) * 2

const getWidgetVerticalPadding = (state: State) =>
  isSingleEventWidget(state) && !isButtonLayout(state) && !isAllComponentsHidden(state)
    ? 108 // Emulate padding even if there is none
    : (parseInt(getElementStyle(getElement(state, CONTENT_CONTAINER_SELECTOR), 'paddingTop'), 10) || 0) * 2

const getContainer = (state: State) => document.querySelector(`#${state.component.id}`)

const getElement = (state: State, selector: string) =>
  isSite(state) ? getContainer(state).querySelector(selector) : document.querySelector(selector)
