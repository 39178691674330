import {getDayMonthText} from '@wix/wix-events-commons-statics/dist/date/date'
import {TranslationFunction} from 'i18next'
import {getFullLocale} from '../../../../../../../commons/selectors/environment'
import {closeMonthlyCalendarPopup} from '../../../../../../actions/calendar-layout'
import {getSelectedDayEvents, EventWithTimeText} from '../../../../../../selectors/calendar-layout'
import {AppProps} from '../../../../../app/interfaces'
import {connect} from '../../../../../runtime-context/connect'
import {MonthlyCalendarEventList as MonthlyCalendarEventListPresentation} from './monthly-calendar-event-list'

export interface MonthlyCalendarEventListOwnProps {
  t: TranslationFunction
}

export interface MonthlyCalendarEventListRuntimeProps {
  dateText: string
  events: EventWithTimeText[]
  closeMonthlyCalendarPopup: typeof closeMonthlyCalendarPopup
  openMonthlyCalendarEvent: (eventId: string) => void
}

export interface MonthlyCalendarEventListProps
  extends MonthlyCalendarEventListRuntimeProps,
    MonthlyCalendarEventListOwnProps {}

const mapRuntime = (context: AppProps): MonthlyCalendarEventListRuntimeProps => ({
  dateText: getDayMonthText(context.state.calendarLayout.monthly.selectedDate, getFullLocale(context.state)),
  events: getSelectedDayEvents(context),
  openMonthlyCalendarEvent: (eventId: string) =>
    context.actions.openMonthlyCalendarEvent(context.state.calendarLayout.monthly.selectedDate, eventId),
  closeMonthlyCalendarPopup: () => context.actions.closeMonthlyCalendarPopup()
})

export const MonthlyCalendarEventList = connect<MonthlyCalendarEventListOwnProps, MonthlyCalendarEventListRuntimeProps>(
  mapRuntime
)(MonthlyCalendarEventListPresentation)
