import {getColorStyle, getFontSize, getSettingsColor} from '@wix/wix-events-commons-statics/dist/settings/style'
import {AppProps} from '../components/app/interfaces'

export const getBorderStyle = (context: AppProps, direction: 'top' | 'right' | 'bottom' | 'left' | '' = '') =>
  getColorStyle(context.style, `border-${direction ? `${direction}-` : ''}color`, 'color-2', 'calendarCellBorderColor')

export const getBorderWidth = (context: AppProps) => context.state.component.settings.calendarCellBorderWidth

export const getActionBackground = (context: AppProps) =>
  getColorStyle(context.style, 'background-color', 'color-8', 'calendarMainActiveColor', 0.2)

export const getOtherMonthBackground = (context: AppProps) => {
  return getSettingsColor(context.style, 'calendarPastDaysBackground')
    ? getColorStyle(context.style, 'background-color', 'color-2', 'calendarPastDaysBackground')
    : getColorStyle(context.style, 'background-color', 'color-2', undefined, 0.1)
}

export const getShareFontSize = (context: AppProps): string => getFontSize(context.style, 'Body-M')
